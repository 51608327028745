
$sm : 4px;
$m : 8px;
.logo{
    max-height:100px;
    width:auto;
    margin-right:16px;
    margin-top: $m;
    margin-bottom: $m;
}
.longMenuItem{
    margin: auto;
}
.page{
    background-image: url('../assets/fwork.jpg');
    color: white;
    min-height: 200px;
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.right{
    margin-right: 40px;
}
.left{
    margin-left: 40px;
}